import React from 'react';

//Icon Imports
import { YoutubeIcon, FacebookIcon, InstagramIcon,FooterOutsideLink } from '../styles';


export const SocialMediaIcons = () => {
  return(
    <>
       <FooterOutsideLink 
      href="https://www.facebook.com/Thechurchinbrielle/" 
      target={'_blank'}
      >
      <FacebookIcon/>
      </FooterOutsideLink>
      <FooterOutsideLink 
      href="https://www.instagram.com/explore/locations/1026619860/the-church-in-brielle/?hl=en" 
      target={'_blank'}
      >
      <InstagramIcon/>
      </FooterOutsideLink>  
      <FooterOutsideLink 
      href="https://www.youtube.com/@ChurchInBrielle" 
      target={'_blank'}
      >
      <YoutubeIcon/>
      </FooterOutsideLink>
    </>
  )
}